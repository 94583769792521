
require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

window.jQuery = $
window.$ = $
require("javascripts/bootstrap.min");
require("javascripts/rsmenu-main");
require("javascripts/jquery.nav");
require("javascripts/isotope.pkgd.min");
require("javascripts/imagesloaded.pkgd.min");
require("javascripts/owl.carousel.min");
window.WOW = require('javascripts/wow.min').WOW;
require("javascripts/skill.bars.jquery");
require("javascripts/jquery.counterup.min");
require("javascripts/swiper.min");
require("javascripts/particles.min");
require("javascripts/jquery.magnific-popup.min");
require("javascripts/plugins");
require("javascripts/pointer");
require("javascripts/contact.form");
require("javascripts/main");